import { configureStore } from '@reduxjs/toolkit';
import globalReducer from '../lib/global.slice';
import selfListingReducer from '../lib/self-listing/selfListing.slice';
import warrantReducer from '../lib/vas/warranty-flow/warranty.slice';
import usedCarsFilterSlice from './used-cars/usedCarsFilter.slice';
import reportListingReducer from './report-listing/reportListing.slice';
import homeReducer from './home/home.slice';

export const makeStore = () => {
  return configureStore({
    reducer: {
      warranty: warrantReducer,
      selfListing: selfListingReducer,
      global: globalReducer,
      usedCarsFilter: usedCarsFilterSlice,
      reportThisListing: reportListingReducer,
      home: homeReducer,
    },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: ['global/setCleverTap'],
          ignoredPaths: ['global.clevertap'],
        },
      }),
  });
};

// Infer the type of makeStore
export type AppStore = ReturnType<typeof makeStore>;
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<AppStore['getState']>;
export type AppDispatch = AppStore['dispatch'];
