export default class ClarityUserIdService {
  static getClarityUserId = async () => {
    if (typeof window !== 'undefined') {
      const cookies = document.cookie.split('; ').reduce((acc: any, cookie) => {
        const [key, value] = cookie.split('=');
        acc[key] = decodeURIComponent(value);
        return acc;
      }, {});
      const clckValue = cookies['_clck'];
      return clckValue ? clckValue.split('|')[0] : null;
    }
  };
}
