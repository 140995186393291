import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { FC, ReactElement, useState } from 'react';
import { AuthService } from '../../../helpers/services';
import { SessionUtils } from '../../../helpers/utilities/session.utils';
import { useAppSelector } from '../../../lib/hooks';
import { useCityContext } from '../../../provider/CityProvider';
import { useGlobalDataContext } from '../../../provider/GlobalDataProvider';
import { useTranslationContext } from '../../../provider/TranslationProvider';
import { ArrowDownIcon, BookmarkIconNew, LocationIcon2 } from '../../icons';
import { UserIcon } from '../../icons/UserIcon';
import LocaleSwitcher from '../LanguageSwitcher';
import HeaderDropdownMenu from './HeaderDropdownMenu';
import Menu from './Menu';

type propTypes = {
  menuItem: any[];
  auth: {
    isAuthenticated: boolean;
    showSignUpButton: boolean;
  };
  onSignInClickHandler: React.MouseEventHandler<HTMLButtonElement>;
  setAuth: any;
  setIsLoggedIn: any;
  setOpenModal: any;
};

const StickyHeader: FC<propTypes> = ({
  menuItem,
  auth,
  setAuth,
  onSignInClickHandler,
  setIsLoggedIn,
  setOpenModal,
}): ReactElement => {
  const router = useRouter();
  const { LabelConstants } = useTranslationContext();
  const [openMenu, setOpenMenu] = useState(false);
  const cleverTap = useAppSelector(({ global }) => global.clevertap);
  const { selectCity } = useCityContext();
  const { generalData } = useGlobalDataContext();
  const openMenuBar = (isOpen: boolean) => {
    setOpenMenu(isOpen);
    if (isOpen) {
      (document.querySelector('body') as HTMLElement).classList.add(
        'body-scroll'
      );
    } else {
      (document.querySelector('body') as HTMLElement).classList.remove(
        'body-scroll'
      );
    }
  };

  return (
    <>
      <header className="header-sticky header header-hide bg-white lg:block hidden">
        <div className="header-top">
          <div className="mx-auto gogo-container px-4">
            <div className="relative flex py-2.5 justify-between">
              <div className="relative flex items-center">
                <div className="flex flex-shrink-0 items-center">
                  {generalData?.Logo?.data?.attributes?.url && (
                    <Link href="/">
                      <a
                        className="flex items-center"
                        onClick={() => openMenu && openMenuBar(false)}
                      >
                        <Image
                          height={34}
                          width={100}
                          src={`${process.env.NEXT_PUBLIC_CMS_API_URL}${generalData?.Logo?.data?.attributes?.url}`}
                          alt={
                            router.locale === 'en'
                              ? "GoGo Motor - Buy and Sell New & Used Cars in Saudi Arabia"
                              : "GoGo Motor - شراء وبيع السيارات الجديدة والمستعملة في السعودية"
                          }
                        />
                        {/* <picture className="cursor-pointer">
                          <source
                            srcSet={`${process.env.NEXT_PUBLIC_Image_CDN_URL}/CMS/Common/gogo-motors.svg`}
                            type="image/svg"
                          />
                          <img
                            src={`${process.env.NEXT_PUBLIC_CMS_API_URL}${generalData?.Logo?.data?.attributes?.url}`}
                            alt="gogo motor"
                            className="!h-[34px] w-auto"
                          />
                        </picture> */}
                      </a>
                    </Link>
                  )}

                  <div className="relative mx-4 lg:block hidden">
                    <button
                      id="locationMenu"
                      className="inline-flex items-center rounded-md bg-white-100 py-2 px-4 text-gray"
                      onClick={() => setOpenModal(true)}
                    >
                      <LocationIcon2 className="w-[17px] h-[17px]" />
                      <span className="mx-2 text-15">{selectCity?.City}</span>
                      <ArrowDownIcon
                        className="h-2.5 w-2.5 text-gray"
                        fill="#3A3A3C"
                      />
                    </button>
                  </div>
                </div>

                <nav
                  className="lg:flex lg:items-center hidden lg:gap-x-10 gap-x-4 py-2 lg:h-12 mx-4"
                  aria-label="Global"
                >
                  {menuItem.map((item, index) => (
                    <Menu {...item.attributes} key={index} />
                  ))}
                </nav>
              </div>

              {/* <div
                id="searchbox"
                className="relative flex flex-1 items-center justify-end px-4 ml-auto hidden"
              >
                <div className="">
                  <div className="relative">
                    <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-6">
                      <svg
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8.12883 14.8334C11.8107 14.8334 14.7955 11.8486 14.7955 8.1667C14.7955 4.48478 11.8107 1.5 8.12883 1.5C4.44692 1.5 1.46213 4.48478 1.46213 8.1667C1.46213 11.8486 4.44692 14.8334 8.12883 14.8334Z"
                          stroke="#6B7588"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M16.4629 16.4999L12.8796 12.9165"
                          stroke="#6B7588"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                    <input
                      id="search"
                      name="search"
                      className="block rounded-full border-0 bg-white-100 py-[9px] pl-14 pr-3 text-gray-100 text-sm focus:outline-none"
                      placeholder="Search your favourite car"
                      type="search"
                    />
                  </div>
                </div>
              </div> */}

              <div className="lg:relative flex items-center">
                {/* <div id="searchicon" className="flex items-center mr-4 ml-auto">
                  <button
                    type="button"
                    className="relative flex-shrink-0 bg-white text-gray"
                  >
                    <SearchIcon fill={'#3A3A3C'} className="h-6 w-6" />
                  </button>
                </div> */}
                {/* <div
                  id="searchbox"
                  className="relative lg:flex hidden flex-1 items-center justify-end px-4 ms-auto"
                >
                  <div className="">
                    <div className="relative">
                      <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-6">
                        <SearchIcon fill={'#6B7588'} />
                      </div>
                      <input
                        id="search"
                        name="search"
                        // value={search}
                        className="!w-[200px] !h-[38px] block rounded-full border-0 bg-white-100 py-[9px] pl-14 pr-3 text-gray-100 text-sm focus:outline-none placeholder-gray-500"
                        placeholder={t(
                          LabelConstants.SEARCH_YOUR_FAVOURITE_CAR
                        )}
                        type="search"
                        onKeyDown={(e: any) => {
                          if (e.key === 'Enter') {
                             cleverTap.event.push('hp_global_search', {
                          search: e.target.value,
                            date: new Date(),
                        });
                            router.push(
                              `/all-listings?searchKey=${e.target.value}`
                            );
                          }
                        }}
                      />
                    </div>
                  </div>
                </div> */}

                <button
                  type="button"
                  className="relative flex-shrink-0 lg:block hidden bg-white text-gray mx-1"
                  title={LabelConstants?.MY_CONCIERGE}
                  id="wishlistid"
                  aria-label="wishlist"
                  onClick={() => {
                    cleverTap.event.push('hp_whislist_menu_clicked');
                    router.push('/wishlist');
                  }}
                >
                  <span className="absolute -inset-1.5"></span>
                  <span className="sr-only">Save</span>
                  <BookmarkIconNew className="w-6 h-6" stroke="#E9E9E9" />
                </button>

                <div className="lg:flex hidden items-center">
                  <div className="border-l border-stone h-12 mx-5"></div>
                </div>

                {!auth.isAuthenticated && auth.showSignUpButton && (
                  <button
                    type="button"
                    id="loginid"
                    aria-label="login"
                    className="relative flex gap-2 items-center flex-shrink-0 text-15 font-medium bg-white text-gray"
                    onClick={onSignInClickHandler}
                  >
                    <UserIcon />
                    <span className="lg:block hidden">
                      {LabelConstants?.LOGIN}
                    </span>
                  </button>
                )}

                {/* {auth?.isAuthenticated && (
                  <button
                    type="button"
                    className="relative flex gap-2 items-center flex-shrink-0 text-15 font-medium bg-white text-gray"
                    onClick={() => {
                      router.push('/profile');
                    }}
                  >
                    <UserIcon />
                    <span className="lg:block hidden">
                      {t(LabelConstants.ACCOUNT)}
                    </span>
                  </button>
                )} */}

                {/* Profile Menu */}
                {auth?.isAuthenticated && (
                  <div className="flex gap-2">
                    {SessionUtils.getUserDetails()?.FirstName ? (
                      <div className="whitespace-nowrap text-primary self-center hidden md:flex items-center gap-1">
                        <div> {LabelConstants?.HELLO},</div>
                        <div className="text-ellipsis w-20 overflow-hidden">
                          {SessionUtils.getUserDetails()?.FirstName}
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                    <HeaderDropdownMenu
                      ariaLabel="profile"
                      setLabelButton={() => (
                        <>
                          <UserIcon />
                          {/* <span className="lg:block hidden">
                            {t(LabelConstants.ACCOUNT)}
                          </span> */}
                        </>
                      )}
                    >
                      {({ close }) => (
                        <ul className="flex flex-col w-52 divide-y">
                          <Link href="/profile">
                            <li
                              className="header-dropdown-item"
                              onClick={() => {
                                close();
                                openMenu && openMenuBar(false);
                              }}
                            >
                              <a className="w-full flex gap-3 items-center">
                                {/* <ProfileIcon className="h-6 w-6" /> */}
                                <span>{LabelConstants?.PROFILE}</span>
                              </a>
                            </li>
                          </Link>
                          <Link href="/my-bookmarks">
                            <li
                              className="header-dropdown-item"
                              onClick={() => {
                                close();
                                openMenu && openMenuBar(false);
                              }}
                            >
                              <a className="w-full flex gap-3 items-center">
                                {/* <MyBookmarkIcon className="h-5 w-5" /> */}
                                <span>{LabelConstants?.MY_BOOKMARKS}</span>
                              </a>
                            </li>
                          </Link>
                          {/* Task 12338: Hide My Quotation link from Profile Menu */}
                          {/* <Link href="/quotations">
                            <li
                              className="header-dropdown-item"
                              onClick={() => close()}
                            >
                              <a className="w-full flex gap-3 items-center">
                                <QuotationIcon className="h-5 w-5" />
                                <span>{t(LabelConstants.MY_QUOTATIONS)}</span>
                              </a>
                            </li>
                          </Link> */}
                          <Link href="/dashboard">
                            <li
                              className="header-dropdown-item"
                              onClick={() => {
                                close();
                                openMenu && openMenuBar(false);
                              }}
                            >
                              <a className="w-full flex gap-3 items-center">
                                {/* <VehicleIcon className="h-5 w-5" /> */}
                                <span>{LabelConstants?.MY_VEHICLES}</span>
                              </a>
                            </li>
                          </Link>
                          <Link href="/my-wishlist">
                            <li
                              className="header-dropdown-item"
                              onClick={() => {
                                close();
                                openMenu && openMenuBar(false);
                              }}
                            >
                              <a className="w-full flex gap-3 items-center">
                                {/* <ConciergeIcon className="h-5 w-5" /> */}
                                <span>{LabelConstants?.MY_CONCIERGE}</span>
                              </a>
                            </li>
                          </Link>
                          {/* <Link href="/my-orders">
                            <li
                              className="header-dropdown-item"
                              onClick={() => {
                                close();
                                openMenu && openMenuBar(false);
                              }}
                            >
                              <a className="w-full flex gap-2 items-center">
                                <span>{t(LabelConstants.MY_ORDERS)}</span>
                              </a>
                            </li>
                          </Link> */}
                          <div
                            className="header-dropdown-item"
                            onClick={async () => {
                              const logoutUrl = SessionUtils.getLogoutURL();
                              await AuthService.signOut(logoutUrl);
                              await AuthService.logout();
                              setAuth();
                              setIsLoggedIn();
                              router.push('/sign-in');
                              close();
                              openMenu && openMenuBar(false);
                              if (logoutUrl) {
                                window.location.href = '/sign-in';
                              }
                            }}
                          >
                            <li className="w-full flex gap-3 items-center">
                              {/* <LogoutIcon className="h-5 w-5" /> */}
                              <span>{LabelConstants?.LOGOUT}</span>
                            </li>
                          </div>
                        </ul>
                      )}
                    </HeaderDropdownMenu>
                  </div>
                )}

                {/* <!-- Language dropdown --> */}
                <div className="relative mx-8 flex-shrink-0 lg:block hidden">
                  <div>
                    <LocaleSwitcher />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default StickyHeader;
