import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import Head from 'next/head';
import { useSeoTagContext } from '../../provider/SeoTagProvider';
import { useCityContext } from '../../provider/CityProvider';
import { useTranslationContext } from '../../provider/TranslationProvider';

const DynamicMeta = () => {
  const router = useRouter();
  const { LabelConstants } = useTranslationContext();
  const { seoList } = useSeoTagContext();
  const { selectCity } = useCityContext();
  const city = selectCity?.City;
  const [metaData, setMetaData] = useState({
    title: LabelConstants.GO_GO_MOTOR_PAGE_META_TITLE_GLOBAL,
    description: LabelConstants.GO_GO_MOTOR_PAGE_META_DESC_GLOBAL,
    keywords: [],
  });
  useEffect(() => {
    const fetchMetaData = async () => {
      const metatag = seoList.find(
        (resp: any) =>
          resp?.attributes?.CanonicalURL === router?.asPath ||
          resp?.attributes?.CanonicalURL === router?.pathname
      );
      // console.log(router.pathname, router.asPath);
      if (metatag) {
        // if (url === '/newcars/new') {
        //   setMetaData({
        //     title: metatag?.attributes?.MetaTitle?.replace('${city}', city),
        //     description: metatag?.attributes?.MetaDescription,
        //     keywords: [],
        //   });
        // } else {
        setMetaData({
          title: metatag?.attributes?.MetaTitle,
          description: metatag?.attributes?.MetaDescription,
          keywords: [],
        });
        // }
      }
    };

    fetchMetaData();
  }, [router.pathname, seoList, router.locale]);

  const getCanonicalUrl = () => {
    // ${router?.asPath}
    return typeof window !== 'undefined' ? `${window?.location?.href}` : '/';
  };

  return (
    <Head>
      <title key={'page-title'}>{metaData.title}</title>
      {process.env.NEXT_PUBLIC_ENABLE_CRAWLING === 'true' ? (
        <></>
      ) : (
        <>
          <meta name="robots" content="noindex" />
          <meta name="googlebot" content="noindex" />
        </>
      )}
      <meta
        name="description"
        content={metaData.description}
        key="meta-data-description"
      />
      <link rel="canonical" href={getCanonicalUrl()} key={'canonicalUrl'} />
      <link
        rel="alternate"
        hrefLang={router?.locale || 'x-default'}
        href={getCanonicalUrl()}
        key={'alternate'}
      />
    </Head>
  );
};

export default DynamicMeta;
